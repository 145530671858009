<template>
  <div>
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3"> {{ $t("questionListPage.pageTitle") }}</strong>
            <CButton color="info" @click="onCreateClicked()">{{
              $t("common.create")
            }}</CButton>
          </CCardHeader>
          <CCardBody>
            <!-- #region Table -->
            <table
              class="edge-type-table table table-sm table-striped table-bordered"
            >
              <thead class="bg-light text-body text-center">
                <tr>
                  <th>{{ $t("questionListPage.name") }}</th>
                  <th width="120">{{ $t("questionListPage.isValid") }}</th>
                  <th width="120">{{ $t("questionListPage.action") }}</th>
                </tr>
              </thead>

              <Draggable
                class="table-row-drag"
                v-model="tableItems"
                @change="onSortEnd"
                v-bind="dragOptions"
                v-if="canShowTable"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                  tag="tbody"
                >
                  <tr v-for="item in tableItems" :key="item.id">
                    <td>{{ item.questionText }}</td>
                    <td class="text-center">
                      <CSwitch
                        class="swc sw-2"
                        color="info"
                        data-on="On"
                        data-off="Off"
                        variant="opposite"
                        :labelOn="$t('common.active')"
                        :labelOff="$t('common.inActive')"
                        :checked="item.status"
                        @update:checked="onStatusChanged(item)"
                      />
                    </td>
                    <td class="text-center">
                      <CButton
                        class=""
                        color="info"
                        square
                        size="sm"
                        @click="onEditClicked(item.id)"
                        ><i class="icon cil-pencil mr-1"></i
                        >{{ $t("common.edit") }}</CButton
                      >
                    </td>
                  </tr>
                </transition-group>
              </Draggable>
            </table>
            <!-- #endregion Table -->
          </CCardBody>
          <transition name="fade">
              <CRow v-if="mediaKindNotFound">
                <CCol>
                  <div class="d-flex justify-content-center">
                    <CAlert
                      color="danger"
                      class="w-50 p-4 text-center"
                      ><h5>{{ $t("questionListPage.notfound") }}</h5></CAlert>
                  </div>
                </CCol>
              </CRow>
          </transition>
        </CCard>
      </CCol>
    </CRow>

    <SuccessModal :successModal="successModal" @close="successModal = $event" />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";

import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

const QUESTION_URL = `/rest/hotel`;
const PAGE_URL = `/operator/question`;

export default {
  name: "QuestionList",
  url: QUESTION_URL,

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true;
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },

    /** drag and drop */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },


    uid(){
      return this.$store.state.uniqueId;
    },
  },
  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      mediaKindNotFound:false,
      //#endregion Flag
      // //#region Message
      errorMessage: "",
      // //#endregion Message

      //#region table
      /** 一覧列名 */
      tableFields: [
        {
          key: "name",
          label: `${i18n.t("questionListPage.name")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "status",
          label: `${i18n.t("questionListPage.isValid")}`,
          _classes: "text-center bg-light",
          _style: "width: 10%",
        },
        {
          key: "action",
          label: `${i18n.t("questionListPage.action")}`,
          _classes: "text-center bg-light",
          _style: "width: 10%",
        },
      ],
      

      /** 一覧データ */
      tableItems: [],
      //#endregion table

      //#region Response data
      response: [],
      //#endregion Response data
    };
  },

  validations() {
    return {
      edit: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },
  methods: {
    //#region Event

    /** 有効無効切替 */
    onStatusChanged(item) {
      this.reqPutStatus(item);
    },

    /** 編集ボタン押下 */
    onEditClicked(id) {
      this.$router.push({
        path: `${PAGE_URL}/${id}/edit`
      });
    },

    /** 新規作成ボタン押下 */
    onCreateClicked() {
      this.$router.push({
        path: `${PAGE_URL}/create`
      });
    },

    /** 一覧表ソート(並び替え) */
    // TODO:開発中
    onSortEnd() {
     // 一覧表の表示順IDを昇順の値にする
      this.tableItems.forEach((a, i) => a.displayOrder = this.order[i]);
      this.reqPutDisplayOrder();
    },

    //#endregion Event

    //#region Request

    reqGet() {
      const url = `${QUESTION_URL}/${this.uid}/question`;
      this.mediaKindNotFound = false;
      this.tableItems = [];

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          const datas = resp.data.list;

          this.response = datas;

          // 取得データを一覧表に流し込み
          this.pourTable(this.response);
          this.createDisplayOrderIdAscList(datas);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.mediaKindNotFound = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          this.PassError = err.response.status;
          this.loading = false;
        });
    },

    /** 有効無効ステータス更新 */
    reqPutStatus(item) {
      const url = `${QUESTION_URL}/${item.id}/status`;
      const body = { status: item.status };

      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          const response = resp.data;
          this.tableItems = new Array(response);
        })
        .catch((err) => {
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
        });
    },

    /** 表示順更新 */
    reqPutDisplayOrder() {
      let body = { list: [] };
      this.tableItems.forEach((a) => {
        body.list.push({ id: a.id, displayOrder: a.displayOrder });
      });

      const url = `${QUESTION_URL}/order`;

      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          this.reqGet();
        })
        .catch((err) => {
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
        });
    },

    //#endregion Request

    //#region Method

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          id: data.id,
          questionText: data.questionText,
          answerText: data.answerText,
          // NOTE:APIへはdisplayOrderで送る必要あり
          displayOrder: data.order ?? data.id,
          status: false,
        });
      });
      console.log(this.tableItems);
    },

    /**
     * 一覧表のIDリストを昇順ソートし、表示順IDリストとする
     * Note: reqGet()までリスト不変の想定
     * @param item
     */
    createDisplayOrderIdAscList(datas) {
      this.order = [];
      datas.forEach((a) => this.order.push(a.id));
      this.order.sort((a, b) => a - b);
      console.log("order")
      console.log(this.order)
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    backPage() {
      this.$router.go(-1);
    },

    //#endregion Method
  },
  beforeMount() {
    this.reqGet();
  },
};
</script>